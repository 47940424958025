.ST-custom-container {
  width: auto;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: calc(100vh - 0px);
  /* height: calc(100vh - 56px); */
  background: url("../011.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.ST-custom-container::-webkit-scrollbar {
  display: none;
}

.ST-r-CONTENT-container::-webkit-scrollbar {
  display: none;
}

.ST-custom-Heigt {
  height: 100vh;
  padding-top: 80px;

  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ST-custom-Heigt::-webkit-scrollbar {
  display: none;
}

.ST-custom-vertical-options::-webkit-scrollbar {
  display: none;
}

.ST-top-title-txt-a {
  font-size: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 100;
  font-family: 'sfPro';
  font-weight: 500;
  margin-top: 28px;
}
@media only screen and (max-width: 767px){
  .ST-top-title-txt-a{
    font-size: 1rem;
  }
}

.ST-top-title-txt-b {
  font-size: 1.688rem;
  letter-spacing: 2px;
  color: #ffffff;
  opacity: 1;
  font-weight: bold;
  font-family: 'sfPro';
}
@media only screen and (max-width: 767px){
  .ST-top-title-txt-b{
    font-size: 1rem;

    
  }
}

.ST-custom-vertical-options {
  height: 60px;
  flex-wrap: nowrap;
  overflow-x: auto;
  background: #5c5c5c1c;
  margin-bottom: 20px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'sfPro';
}
.activeClassSettings{
  color: #d20056 !important;
  padding: 10px;
  border-radius: 100px;
  background: #1a1a1aa9 0% 0% no-repeat padding-box;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}
.ST-custom-vertical-options-btn {
  background: transparent;
  border: none;
  letter-spacing: 1.4px;
  color: #f2f2f2e1;
  opacity: 1;
  flex: 0 0 auto;
  font-weight: 500;
  font-family: 'sfPro';
  margin: 5px;
}

.ST-custom-vertical-options-btn:hover {
  /* color: #d20056; */
  color: #f2f2f2e1;
}

.ST-inputs-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 14.3px;
  margin-top: 10px;
  font-family: sfPro;
}

.ST-inputs-title-c {
  color: #ffffff;
  font-weight: bold;
  font-size: 14.3px;
  margin-top: 5px;
}
.containerlabel{
  display: flex;
}
.colorred{
  color: red;
  margin-top: 5px;
  font-weight: bold;
}
.select{
  color: red !important;
}
.ST-inputs-container {
  display: flex;
  margin-top: 10px;

  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  padding-left: 10px;
  background:#0E1010;
}
.ST-inputs-container:hover, .ST-inputs-container-bt:hover, .ST-inputs-container2:hover{
  border: 1px solid #D20056;
}

.ST-inputs-container2 {
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  height: 150px;
  max-height: 150px;
  width: 100%;
  padding-left: 10px;
  background: #0E1010;
}

.ST-inputs-container-bt {
  display: flex;
  margin-top: 10px;

  align-items: center;
  justify-content: center;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  padding-left: 10px;
  background: #0f0f0fcb 0% 0% no-repeat padding-box;

  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}

.form-control,.form-control:focus {
  color: #6f6f6f;
  background-color: #0000;
  border: none;
  border-radius: .25rem;
}

.inputnumber1{
  padding-right: 10px;
}

.ST-input-l {
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  margin-left: 5px;
  width: 95%;
}

.ST-input-l-bt {
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  margin-left: 5px;
  width: 95px;
}
.ST-input-l-bt {
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  margin-left: 5px;
  width: 100%;
}

.ST-input-l-c {
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  margin-left: 5px;
  width: 95%;
}

.ST-input-l-c2 {
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  margin-left: 5px;
  width: 95%;
  height: 100%;
}

textarea {
  resize: none;
}

.ST-l-Selecte {
  width: 95%;

  background: transparent;
  outline: none;
  height: 40px;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  color: #656565;;
  opacity: 0.44;
}

.ST-bt-inputs-container {
  display: flex;

  align-items: center;
  justify-content: space-between;
}

.ST-center-container {
  display: flex;
  flex-direction: column;
  justify-content: normal;
  align-items: center;
}

.ST-center-title {
  margin-top: 15px;
  color: #ffffff;
  opacity: 1;
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 20px;
}
.subtitle{
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  padding-bottom: 20px;
  font-family: sfPro;
}
@media only screen and (min-width:1231px) and (max-width:1400px){
  .subtitle{
    margin-top: 10px !important;

  }
  

  .live-chat{
    margin-top: 40px;
  }
}
@media only screen and (min-width:1231px) and (max-width:1425px){
  .subtitle{
    margin-top: 10px !important;

  }
  

  .live-chat{
    margin-top: 40px;
  }
}



.ST-center-image-container {
  width: 95%;
  background: rgba(255, 255, 255, 0.212);
  height: 400px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.ST-center-btn-container {
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerPostChallenge{
  padding-bottom: 20px;
}

.ST-c-c-bt {
  background: transparent;
  border: 1px solid #fff;
  color: #ffffff;
  width: 160px;
  height: 55px;
  border-radius: 10px;
  font-weight: bold;
  margin-right: 10px;
}

.ST-c-l-bt {
  background: #d2005633;
  font-weight: bold;
  border: 1px solid#D200568F;
  color: #ffffff;
  width: 160px;
  height: 55px;
  border-radius: 10px;
}

.ST-rigt-title {
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 2px;
}

.ST-rigt-input-container {
  height: 55px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(168, 168, 168, 0.493);
}

.ST-rigt--input-container {
  background: #121314;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
}
.ST-rigth-input {
  border: none;
  outline: none;
  border-radius: 4px;
  color: #fff;
  background: transparent;
  font-size: 15px;
  margin-left: 15px;
  width: 200px;
  font-family: sfPro;
}

.ST-i {
  margin-left: -40px;
}

.ST-r-CONTENT-container {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 85%;
}

.Post-Challange-custom-height {
  height: calc(100vh - 215px);
}

@media (min-width: 992px) {
  .ST-custom-container {
    margin-left: 230px;
    background: url("../011.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;

    height: calc(100vh - 0px);
    /* height: calc(100vh - 56px); */

  }

  .ST-custom-container2 {
    margin-left: 64px;
    background: url("../011.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;

    height: calc(100vh - 0px);
    /* height: calc(100vh - 56px); */
  }
}

/*My Friends*/
.container-container{
  height: 380px;
	overflow-y: scroll;
}
.container-container::-webkit-scrollbar{
  display: none;
}
.ST-r-CONTENT-container{
height: auto;
}
.container-friend, .container-add-friend{
  padding: 10px;
  border-radius: 10px;
}
.container-friend:hover, .container-add-friend:hover {
  background-color: #393939;
}
.container-circle{
margin-top: -30px;
margin-left: -10px;
}
.activee{
  color: #00D463;
  filter: blur(1px);
  font-size: 12px;
  position: relative;
}
.container-seccion1{
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.user-styles {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  padding-right: 5px;
}
.name{
  color: white;
  padding-right: 5px;
  padding-left: 5px;
}
.status{
  color: #00D463;
  margin-left: 60px;
  margin-top: -7px;
}
.status2{
  color: #9b9b9b;
  margin-left: 40px;
  margin-top: -10px;
  font-size: 12px;
}
.icon-sms, .icon-more{
  color: #f2f2f2;
}
.container-add-friend{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.container1{
  display: flex;
  border-right: 1px solid rgb(82, 82, 82);
  padding-right: 15px;
}
.container2{
  display: flex;
}
.icon-plus, .icon-link{
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
.icon-plus{
  color: rgb(197, 195, 195);
}
.friend, .Invite{
  font-size: .8rem;
}
.friend{
  color: rgb(197, 195, 195);
}
.icon-link, .Invite{
  color: #D20056;
}
.fa-plus-circle:hover{
  color: white;
}

/*My Friends*/

/*select*/
select {
  color: white !important;
}
select:focus{
  background-color: #0d0d0d;
}



/*///////////////////////////////////////////////////////////*/
.Comming-Soon-Settings{
  color: white;
  font-size: 4rem;
  font-style: italic;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: blur(0.7px);
  -webkit-text-stroke: 2px #bf575b;
}


