.Chat-Page-custom-H {
  margin-top:15px;
}

.C-custom-left-title {
  color: rgba(255, 255, 255, 0.945);

  margin: 5px;
  font-weight: 500;
  font-family: 'sfPro';
  letter-spacing: 2px;
  font-size: 19px;

  display: flex;
  align-items: center;
}

.C-custom-left-container {
  margin: 5px;
  border-bottom: 1px solid #70707038;
  padding-bottom: 20px;
}

.C-custom-left-container-List {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin: 5px;
  height: calc(60vh);
}

.C-custom-left-container-List::-webkit-scrollbar {
  display: none;
}

.C-custom-left-container-List2 {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin: 5px;
  height: calc(60vh);
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
@media (min-width: 1600px){
  .C-custom-left-container-List,.C-custom-left-container-List2{
    height: calc(50vh) !important;
  }

}

.C-custom-left-container-List2::-webkit-scrollbar {
  display: none;
}

.C-P-list-Item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px;
  cursor: pointer;
}
.C-P-list-Item.active{
  background:#54d37c !important
}
.C-P-list-Item.active i{
  color:white !important
}
.C-P-list-Item:hover, .C-P-list-Item.active{
  margin-top: 5px;

  background: #161616;
  border: 0.5px solid #70707019;
  border-radius: 6px;
}
.C-P-list-Item.unselect{
  border-color : red !important ;
  border-width: 2px !important;
}
.C-P-list-Item:focus {
  margin-top: 5px;

  background: #161616;
  border: 0.5px solid #70707019;
  border-radius: 6px;
}

.C-input {
  width: 100%;
}

.C-P-list-Item-image-container {
  width: 26%;
  display: flex;
  padding-left: 8px;
}

.C-P-list-Item-Image {
  width: 45px;
  height: 45px;
  background: #818181;
  border-radius: 50%
}

.C-P-list-Item-txt-container {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.C-P-list-txt-title {
  display: flex;
  flex-direction: column;
  font-family: sfPro;
  margin-left: 15px;
}

.C-P-list-Item-tv {
  display: flex;
  align-items: center;
}

.C-P-list-username {
  font-size: 14px;
  word-break: break-all;
  font-weight: 500;
  color: #fff;
}

.C-P-list-Item-circle {
  background: #d2005627;
  color: #d20056;
  margin-top: 2px;
  width: 20px;
  margin-right: 10px;
  display: flex;
  height: 20px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  margin-left: 5px;
}

.H-custom-i {
  color: #f2f2f283;
  margin-top: 2px;
  margin-left: 10px;
}

.C-P-list-oneline-txt {
  color: #00d463;
  font-size: 12.5px;
}

.C-P-list-oneline-txt-level {
  color: #a1a1a1bb;
  font-size: 12.5px;
}

.C-P-list-Item-description {
  color: #f2f2f2;
  opacity: 0.5;
  font-size: 12px;
  margin-left: 13px;
  margin-top: -2px;
}
.dont-have-friends {
  color: white;
  font-size: 0.8rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.imgnotfound {
  width: 100px;
  height: 100px;
}

.team-line {
  margin-top: -12px;
  height: 10px;
  border-bottom: 1px solid rgba(124, 124, 124, 0.329);
}
.C-P-I {
  margin-left: 10px;
  margin-right: 10px;
}
.cuastom-Share-i {
  margin-right: 10px;
  margin-left: 10px;
}
.cuastom-Share-i:hover {
  color: white;
}
.just {
  border: 1px solid #f2f2f20d;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(31px);
  -webkit-backdrop-filter: blur(31px);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  z-index: 1;
}
.C-P-list-Item-Image-chat {
  width: 35px;
  height: 35px;
  border-radius: 100px;
  margin-left: 10px;
  border: 2px solid white;
  margin-right: 10px;
  box-shadow: 1px 1px 10px -1px white;
}

.addFreindsContainer {
  display: flex;
  border-radius: 5px;
  background: #161616;
  padding: 5px;
  margin: 5px;
  justify-content: space-around;
}
.section1 {
  display: flex;
  color: #ffffff78;
  align-items: center;
  border-right: #ffffff78 1px solid;
}

.pstyles {
  display: flex;
  padding: 0;
  margin: 0;
  font-size: 11px;
  padding-right: 5px;
  padding-left: 5px;
}
.section2 {
  display: flex;
  align-items: center;
  color: #d20056;
  padding-left: 5px;
}
.fa-link:before {
  content: "\f0c1";
  font-size: 11px I !important;
  color: #d20056 !important;
  padding-right: 5px !important;
}
.p2styles {
  font-size: 15px;
  padding: 0;
  margin: 0;
}
.userNotFound {
  color: white;
  font-size: 1rem;
}
.searchAndFindNewFriends {
  color: white;
  font-size: 0.8rem;
  margin-top: 1rem;
}

@media only screen and (max-width: 1240px) and (min-width: 991px) {
  .C-P-list-Item-tv {
    display: flex;
    align-items: center;
    margin-top: -15px !important;
  }
  .C-P-list-Item {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }
  .C-P-list-Item-image-container {
    padding-left: 0px;
  }

  .section1 {
    display: flex;
    color: #ffffff78;
    border-right: #ffffff78 1px solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section2 {
    display: flex;
    align-items: center;
    color: #d20056;
    padding-left: 5px;
    flex-direction: column;
  }
}
@media only screen and (max-width: 900px) and (min-width: 768px) {
  .C-P-list-Item-tv {
    display: flex;
    align-items: center;
    margin-top: -15px !important;
  }
  .C-P-list-Item {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    flex-direction: column;
    align-items: center;
  }
  .C-P-list-Item-image-container {
    padding-left: 0px;
  }
  .section1 {
    display: flex;
    color: #ffffff78;
    border-right: #ffffff78 1px solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .section2 {
    display: flex;
    align-items: center;
    color: #d20056;
    padding-left: 5px;
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) and (min-width: 400px) {
  .C-P-list-Item-image-container {
    width: auto;
  }
  .C-P-list-Item {
    margin-top: 0px;
  }
  .C-P-list-Item-tv {
    display: flex;
    margin-top: -20px;
    justify-content: space-between;
  }
  .C-custom-left-container-List2 {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: normal;
  }
}
@media only screen and (max-width: 399px) and (min-width: 320px) {
  .C-custom-left-container-List2 {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: normal;
  }
}

.C-container-title {
  font-weight: bold;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.911);
  font-size: 20px;
}

.C-chat-message-input-container {
  background: #1c1a18 0% 0% no-repeat padding-box;
  border: 0.5px solid #f2f2f20d;
  backdrop-filter: blur(31 px);
  -webkit-backdrop-filter: blur(31px);
  margin: 0 auto;
  width: 95%;
  height: 40px;
  color: #fff;
  border-radius: 30px;
  display: flex;
  z-index: 99999;
  align-items: center;
  font-family: sfPro;
}

.C-chat-message-input {
  background: transparent;
  margin-left: 15px;

  border: none;

  outline: none;
  letter-spacing: 0.8px;
  color: #f2f2f2;
  font-size: 14px;

  width: 75%;
}

.C-chat-message-buttons {
  width: 25%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.C-chat-message-buttons .space{
  width: 10px;
}

.C-chat-message-btn {
  background: transparent;

  color: rgba(255, 255, 255, 0.89);
  border: none;

  outline: none;

  font-size: 16px;
}

.C-chat-message-btn-send {
  background: #d20056 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 60px #d2005667;
  border-radius: 100px;

  color: rgba(255, 255, 255, 0.822);
  border: none;

  outline: none;
  height: 35px;
  width: 35px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.C-chat-messages {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100%;
  display: flex;
  max-height: 50vh;
  flex-direction: column;
  margin-bottom: 20px;
  padding-top: 30px;
}

.C-chat-messages::-webkit-scrollbar {
  display: none;
}

.LH {
  align-self: flex-start;
  /* margin-left: 20px; */
  margin-bottom: 10px;
  color: rgb(242 242 242 / 50%);
  font-family: sfPro;
  font-weight: 300;
  padding: 10px 20px;
  background: #ffffff1f 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 10px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}
.content-LH{
  display: flex
}
.content-RH{
  display: flex;
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.img-space{
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.RH {
  align-self: flex-end;
  /* margin-right: 20px; */
  margin-bottom: 10px;
  color: #fff;
  padding: 10px 20px;
  font-family: sfPro;
  font-weight: 300;

  background: #bbbbbb15 0% 0% no-repeat padding-box;
  border-radius: 10px 0px 10px 10px;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}
.RH img, .LH img{
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

/*My Friends*/
.container-container {
  height: 380px;
  overflow-y: scroll;
}
.container-container::-webkit-scrollbar {
  display: none;
}
.ST-r-CONTENT-container {
  height: auto;
}
.container-friend,
.container-add-friend {
  padding: 10px;
  border-radius: 10px;
}
.container-friend:hover,
.container-add-friend:hover {
  background-color: #393939;
}
.container-circle {
  margin-top: -30px;
  margin-left: -10px;
}
.activee {
  color: #00d463;
  filter: blur(1px);
  font-size: 12px;
  position: relative;
}
.container-seccion1 {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
.user-styles {
  width: 45px;
  height: 45px;
  border-radius: 100%;
  padding-right: 5px;
}
.name {
  color: white;
  padding-right: 5px;
  padding-left: 5px;
}
.status {
  color: #00d463;
  margin-left: 60px;
  margin-top: -7px;
}
.status2 {
  color: #9b9b9b;
  margin-left: 40px;
  margin-top: -10px;
  font-size: 12px;
}
.icon-sms,
.icon-more {
  color: #f2f2f2;
}
.container-add-friend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.container1 {
  display: flex;
  border-right: 1px solid rgb(82, 82, 82);
  padding-right: 15px;
}
.container2 {
  display: flex;
}
.icon-plus,
.icon-link {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
}
.icon-plus {
  color: rgb(197, 195, 195);
}
.friend,
.Invite {
  font-size: 0.8rem;
}
.friend {
  color: rgb(197, 195, 195);
}
.icon-link,
.Invite {
  color: #d20056;
}
