.UserID,
.ManaBalance,
.Currency,
.Dollar_Currency {
  color: rgba(255, 255, 255, 0.479);
  font-size: 1.2rem;
  border-bottom: 1px solid;
  padding-top: 10px;
}

.userIDnumber {
  font-size: 0.8rem;
  color: white;
  padding-top: 10px;
}

.ManaBalanceNumber,
.dollar,
.dolar_value {
  color: white;
  font-size: 0.8rem;
  padding-top: 10px;
}

.ContainerUserID {
  display: flex;
  flex-direction: column;
}

.ContainerWallet {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 670px) and (min-width: 320px) {
  .ContainerWallet {
    display: initial;
  }
}

.ContainerINputt {
  width: 200px;
}

.btnMargin {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.ColorWhite {
  color: white;
}

.MuiButton-outlinedPrimary {
  height: 50px !important;
  margin-bottom: 5px !important;
}

.ColorWhiteAddressBalance {
  color: white;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

@media only screen and (max-width: 425px) and (min-width: 320px) {
  .ColorWhiteAddressBalance {
    font-size: 11px;
    justify-content: flex-start;
  }

  .ColorWhite2 {
    font-size: 12px;
  }

  .Containerinputs {
    display: flex;
  }
}

.Containerbtnsendtransaction {
  display: flex;
  justify-content: center;
}

.card-wallet {
  background: #151717 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff0d;
  border-radius: 2.188rem;
  opacity: 1;
}

.address {
  display: flex;
  justify-content: space-around;
  height: 3.75rem;
  background: #1b1b1c 0% 0% no-repeat padding-box;
  border: 0.800000011920929px solid #ffffff08;
  border-radius: 0.938rem;
  opacity: 1;
  align-items: center;
  margin-bottom: 1.25rem;
  font-family: sans-serif;
  font-size: 1rem;
  overflow: hidden;
}

.icon-copy {
  color: white;
}

.icon-copy:hover {
  color: #d20056;
}

.addressAccount {
  text-align: left;
  font: normal normal medium 16px/26px SF UI Display;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.containerCurrentBalance {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1b1b1c 0% 0% no-repeat padding-box;
  border: 0.800000011920929px solid #ffffff0a;
  border-radius: 1.563rem;
  opacity: 1;
}

.currenceBalanceC {
  display: flex;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-top: 1.688rem;
  padding-bottom: 1.688rem;
  align-items: center;
  justify-content: space-evenly;
}

.currenceBalance {
  text-align: left;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  /* font-size: 1.25rem; */
  font-size: 1rem;
  font-weight: 500;
  font-family: sans-serif;
  margin: 0;
  padding-right: 2rem;
}

.dolarBalance {
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.75rem;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin: 0;
}

.flowBalance {
  text-align: left;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-size: 1rem;
  font-family: sans-serif;
  font-weight: 500;
  margin: 0;
}

.btn {
  background: #1b1b1c 0% 0% no-repeat padding-box;
  border: 0.800000011920929px solid #ffffff08;
  border-radius: 18px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.icon-btn {
  color: white;
  font-size: 2rem;
}

.icon-btn:hover {
  color: #d20056;
  transition: color 0.6s;
}

.deposit,
.history {
  text-align: left;
  font-size: 1rem;
  font-family: sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.addresss {
  margin-top: 1.25rem;
  height: 3.75rem;
  background: #1b1b1c 0% 0% no-repeat padding-box;
  border: 0.800000011920929px solid #ffffff08;
  border-radius: 0.938rem;
  opacity: 1;
  display: flex;
  align-content: center;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}

.blockchainAddress {
  background-color: transparent;
  color: #707070;
  border: none;
  width: 100%;
}

.blockchainAddress :focus {
  background-color: transparent;
  border: transparent !important;
}

.blockchainAddress :focus-visible {
  background-color: transparent;
  border: none !important;
}

.send {
  height: 3.813rem;
  background: #286efa 0% 0% no-repeat padding-box;
  border: 1px solid #286efa;
  border-radius: 0.938rem;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 500;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.tab-wallet {
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 1rem;
  font-weight: 500;
  font-family: sans-serif;
  padding-top: 1.2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ffffff0d;
  margin-bottom: 1rem;
}

#start-item-txt {
  border-top-left-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

#finally-item-txt {
  border-top-right-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.table-wallet thead th {
  background: #181819 0 0 no-repeat padding-box;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  border: none;
  border-style: hidden;
  opacity: 1;
}

.tableBody {
  text-align: left;
  font-weight: 500;
  font-size: 0.875rem;
  font-family: sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

#view-txt {
  color: white;
}

#view-txt:hover {
  color: #ff2626 !important;
}

#view-txt:focus {
  color: white;
}

.badge-success-td {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: rgba(0, 128, 0, 0.1098039216);
  color: #8dfb86;
}

.badge-error-td {
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: rgba(128, 0, 0, 0.11);
  color: #fb8b86;
}

.tableRow {
  /* border-style: hidden; */
  border-bottom: 2px solid #ffffff0d;
}

.paddingTable {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 0px;
}

@media only screen and (min-width: 993px) and (max-width: 1115px) {
  .sizeTable {
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
  }
}

.heightTable {
  height: 500px;
}

.tableInfo {
  height: 500px;
  overflow: scroll;
  scrollbar-width: none;
}



.heightTable::-webkit-scrollbar {
  display: none;
}

.withoutTransactions {
  width: max-content;
  margin-top: 41px;
  padding-left: 20px;
}

.default- {
  font-size: 1.5rem;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-P,
.hashInternalWallet {
  text-align: left;
  font-size: 1rem;
  font-family: sans-serif;
  font-weight: 500;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
}

.hashInternalWallet:hover {
  color: #ff2626;
  cursor: pointer;
}