.ContainerInputs {
  display: flex;
  flex-direction: column;
}
.ST-inputs-container-end {
  margin-bottom: 10px;
}
.ST-c-c-bt-send {
  background: transparent;
  border: 1px solid #fff;
  color: #ffffff;
  width: 80px;
  height: 40px;
  border-radius: 10px;
  font-weight: bold;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.buttonContainerSave {
  display: flex;
  justify-content: center;
}
.ST-input-l-photo {
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  margin-left: 5px;
  width: 95%;
  opacity: 0;
}
.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  color: #aeaed5;
}

.file-upload-wrapper:after {
  content: attr(data-text);
  position: absolute;

  width: calc(90%);
  pointer-events: none;
  z-index: 20;
  line-height: 30px;
  color: white;
  font-weight: 400;
  overflow: hidden;
}

.titleReport {
  font-size: 0.8rem;
  color: white;
}
.inputEmailReport {
  background-color: red;
}

.Container-btn-send {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.sendReport {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  width: 80px;
  height: 40px;
  border-radius: 10px;
  font-weight: bold;
}
.sendReport:hover {
  color: rgba(255, 255, 255, 0.315);
}

.ST-input-l {
  background: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  margin-left: 5px;
  width: 95%;
  height: 40px;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  color: #aeaed5;
  height: 40px;
}

.container-img-profile {
  width: 60px;
  margin-top: 5px;
  height: 60px;
  margin-left: 20px;
  border: 0.4px solid rgba(107, 107, 107, 0.692);
}

.containerchange-img-section {
  display: flex;
  flex-direction: row;
}

.ST-c-c-bt-send-change-photo {
  background: transparent;
  border: 1px solid #fff;
  color: #ffffff;
  width: 130px;
  height: 40px;
  border-radius: 10px;
  font-weight: bold;
  margin-right: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.GamesPlayedTitle {
  color: white;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  align-items: center;
  border-top: 1px #202122 solid;
  font-size: 1.3rem;
}

.listGames {
  color: white;
  font-size: 0.8rem;
}

.stylesthead {
  color: #d20056 !important;
}

.marginbtn {
  margin-bottom: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.inputGamerTags {
  border: none;
  background: none;
  color: #fff;
  outline: none;
  font-size: 0.7rem;
  border-bottom: 0.4px solid rgba(107, 107, 107, 0.692);
}

.GameplayedName {
  padding: 5px;
}

.GameplayedName:hover {
  background-color: rgba(255, 255, 255, 0.137);
}

.tablecontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stylesTitle {
  padding-right: 10px;
  padding-left: 10px;
}

.pepe {
  display: flex;
  flex-direction: column;
}

.containerAddNewGame {
  display: flex;
}

.newsize {
  width: 32% !important;
  margin-right: 20px;
  font-size: 0.75rem;
}

.ST-l-Selecte-2 {
  width: 95%;
  background: transparent;
  outline: none;
  height: 40px;
  border: none;
  color: #656565;
  opacity: 0.44;
}