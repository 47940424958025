.centerConten{
    display: flex;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.container-dragAndDrop {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-size: 25px 50px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: white;
  overflow: hidden !important;
}
.stylestext {
  display: flex;
  font-size: 0.8rem;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-all;
  padding: 0px 20px;
  text-align: center;
  margin-top: -40% !important;
  color: white;
}
