.containerBtnSave{
    display: flex;
    justify-content: center;
    align-items: center;
}
.changePassword {
    width: 100px;
    height: 30px;
    bottom: -15%;
    border: none;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
    background-color: #286efa;
    font-family: "Nunito Sans",sans-serif;
    margin-top: 40px;
}

.inputStyles{
    width: 100%;
    border: none;
    border-bottom: 0.4px solid hsla(0,0%,42%,.692);
    background: none;
    color: #fff;
    outline: none;
    font-size: .7rem;
}

.Auth-pages-top-bar21 {
    width: 60%;
    height: 85%;
    /* background-color: rgb(28 34 39 / 80%); */
    background: rgba(0,0,0,.44313725490196076);
    position: absolute;
    padding: 20px;
  }

  .changePasswordtitle{
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-bottom: 40px;
  }

  .containerOldPassword,.containerNewPassword{
      display: flex;
      flex-direction: column;
  }
  .containerNewPassword{
      padding-top: 20px;
  }

  .colorLable{
      color: white;
      padding-bottom: 15px;
      font-size: 1rem;
  }

  .containerChangePassword{
      display: flex;
      justify-content: center;
      padding-top: 20px;
  }

  .changePasswordd {
    width: 150px;
    height: 30px;
    bottom: -15%;
    border: none;
    border-radius: 100px;
    color: #fff;
    cursor: pointer;
    background-color: #286efa;
    font-family: "Nunito Sans",sans-serif;
    margin-top: 40px;
}
  .Auth-pages-container-complete-profile-Change-Password {
    width: 100%;
    height: calc(100vh - 56px);
    display: flex;
    justify-content: center;
    background-color: rgb(204, 180, 180);
    position: absolute;
    background-image: url("../bg.png");
    background-size: cover;
    background-repeat: no-repeat;
}

@media only screen and (max-height: 540px) and (min-height: 500px) {
    .Auth-pages-container-complete-profile-Change-Password {
      /* height: calc(110vh -56px); */
      height: calc(110vh);
    }
  }
  @media only screen and (max-height: 499px) and (min-height: 450px) {
    .Auth-pages-container-complete-profile-Change-Password {
      height: calc(120vh);

    }
  }
  @media only screen and (max-height: 449px) and (min-height: 400px) {
    .Auth-pages-container-complete-profile-Change-Password {
      height: calc(130vh);

    }
  }
  @media only screen and (max-height: 399px) and (min-height: 350px) {
    .Auth-pages-container-complete-profile-Change-Password {
      height: calc(150vh);

    }
  }
  @media only screen and (max-height: 349px) and (min-height: 310px) {
    .Auth-pages-container-complete-profile-Change-Password {
      height: calc(170vh);

    }
  }
  @media only screen and (max-height: 309px) and (min-height: 280px) {
    .Auth-pages-container-complete-profile-Change-Password{
      height: calc(190vh);

    }
  }
  @media only screen and (max-height: 279px) and (min-height: 260px) {
    .Auth-pages-container-complete-profile-Change-Password {
      height: calc(200vh);

    }
  }
  @media only screen and (max-height: 259px) and (min-height: 240px) {
  .Auth-pages-container-complete-profile-Change-Password {
      height: calc(220vh);

    }
  }
  @media only screen and (max-height: 239px) and (min-height: 210px) {
    .Auth-pages-container-complete-profile-Change-Password {
      height: calc(250vh);

    }
  }
  @media only screen and (max-height: 209px) and (min-height: 200px) {
   .Auth-pages-container-complete-profile-Change-Password{
      height: calc(260vh);

    }
  }

@media only screen and (max-width: 900px) and (min-width: 320px){
  .Auth-pages-top-bar21{
    width: 75%;
  }
}

/*verify  Code*/

.containerNewPasswordd {
  display: unset;
}

.top-input-emaill,.top-input-firstNamee{
  border: none;
  border-bottom: 0.4px solid rgba(107, 107, 107, 0.692);
  background: none;
  color: #fff;
  width: 95%;
  outline: none;
  font-size: 0.7rem;
  padding-top: 20px;
}