@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
body,
button {
  font-family: "Inter", sans-serif;
}

 .sidebar-nav {
  width: 230px;
  background: linear-gradient(10deg, #231b4d 0%, #24071b 64%, #100e2c 100%);
  display: flex;
  align-items: center;
  border: none;
  border-right: 0px solid #ffffff00;
  z-index: 9999998;


  position: fixed !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  margin-top: "10em" !important;
}
.sidebar-nav::-webkit-scrollbar{
  display: none;
} 

.sidebar-navv {
  width: 64px;
  background: linear-gradient(10deg, #231b4d 0%, #24071b 64%, #100e2c 100%);
  display: flex;
  align-items: center;
  border: none;
  border-right: 0px solid #ffffff00;
  z-index: 9999998;


  position: fixed !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  margin-top: "10em" !important;
}
.sidebar-navv::-webkit-scrollbar{
  display: none;
} 

.user-image-container{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  background: #ffffff34;
  width: 89px;
  height: 89px;
  border-radius: 100px;
}
.user-image-container2{
  display: none;
  justify-content: center;
  margin-top: 20px;
  background: #ffffff34;
  width: 89px;
  height: 89px;
  border-radius: 100px;
}
.left-bar-image {
  width: 89px;
  height: 89px;
  border-radius: 100px;
}

.menu-txt-container {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font: normal normal medium 23px/28px SF UI Display;
  letter-spacing: 1.64px;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  opacity: 1;
}
.menu-options-Top-container {
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font: normal normal medium 23px/28px SF UI Display;
  letter-spacing: 1.64px;
  color: #b6b6b6;

  opacity: 1;
}

.menu-options-container {
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font: normal normal medium 23px/28px SF UI Display;
  letter-spacing: 1.64px;
  color: #b6b6b6;

  opacity: 1;
}

.menu-i-container {
  text-align: left;
  font: normal normal medium 23px/28px SF UI Display;
  letter-spacing: 1.64px;

  font-size: 13.5px;

  margin-left: 20px;
  color: #ffffffa6;
  cursor: pointer;
}

.activeClass{
  color: white;
}
.menu-i-container:hover{
  color: rgb(255, 255, 255) !important;
}

a{
  text-decoration: none;
}
.menu-T-container {
  text-align: left;
  font: normal normal medium 23px/28px SF UI Display;
  letter-spacing: 1.64px;
  color: #ffffffec;

  opacity: 1;
  margin-left: 20px;
}

.sidebar-txt-user {
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.sidebar-txt-user2{
  display: none;
}

.sidebar-txt-profile {
  display: flex;
  color: rgba(206, 206, 206, 0.747);
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-size: 13px;
}

@media (min-width: 992px) {
   .sidebar-nav {
    width: 230px;
    transform: none;
    visibility: visible !important;
    top: 86px;
    border: none;
    border-right: 0px solid #ffffff00;

    position: fixed !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    margin-top: "10em" !important;
  }

  .sidebar-nav::-webkit-scrollbar {
    overflow-y: hidden;
  } 


  .sidebar-navv {
    width: 64px;
    transform: none;
    visibility: visible !important;
    top: 56px;
    border: none;
    border-right: 0px solid #ffffff00;
    z-index: 9999998;

    position: fixed !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    margin-top: "10em" !important;
  }

  .sidebar-navv::-webkit-scrollbar {
    overflow-y: hidden;
  }
}
@media(max-width:768px) {
  .sidebar-nav {
    top: 50px;
  }
}
