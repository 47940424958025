
.ql-editor {
  font-size: 16px; 
  color: #ffffff; 
}


.ql-container {
  background-color: #0E1010; 
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  
}


.ql-toolbar {
  background: transparent
  linear-gradient(
    39deg,
    rgba(19, 12, 59, 0.6) 0%,
    rgba(56, 9, 40, 0.6) 64%,
    rgba(11, 10, 48, 0.6) 100%
  );
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

}

.character-count{
color: #ffffff;
  
}

.ql-modules{
  background-color: #ffffff; 
}

.qlformats{
  background-color: #ffffff; 
}
.ql-picker-label{
color:#ffffff
}
.ql-toolbar button svg{
  color: #ffffff;
}
  .ql-toolbar button {
    background:color white;
    border: none;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 24px;
    padding: 3px 5px;
    width: 30px;
}
.ql-stroke button{
color: #ffffff;
}