.Auth-pages-container {
  width: 100%;
  height: 100%;
  background-color: rgb(204, 180, 180);
  position: absolute;
  background-image: url("../bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.Auth-pages-container-complete-profile {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  background-color: rgb(204, 180, 180);
  position: absolute;
  background-image: url("../bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-height: 540px) and (min-height: 500px) {
  .Auth-pages-container,.Auth-pages-container-complete-profile {
    height: 110%;
  }
}
@media only screen and (max-height: 499px) and (min-height: 450px) {
  .Auth-pages-container,.Auth-pages-container-complete-profile {
    height: 120%;
  }
}
@media only screen and (max-height: 449px) and (min-height: 400px) {
  .Auth-pages-container,.Auth-pages-container-complete-profile {
    height: 130%;
  }
}
@media only screen and (max-height: 399px) and (min-height: 350px) {
  .Auth-pages-container,.Auth-pages-container-complete-profile {
    height: 150%;
  }
}
@media only screen and (max-height: 349px) and (min-height: 310px) {
  .Auth-pages-container,.Auth-pages-container-complete-profile {
    height: 170%;
  }
}
@media only screen and (max-height: 309px) and (min-height: 280px) {
  .Auth-pages-container,.Auth-pages-container-complete-profile {
    height: 190%;
  }
}
@media only screen and (max-height: 279px) and (min-height: 260px) {
  .Auth-pages-container,.Auth-pages-container-complete-profile {
    height: 200%;
  }
}
@media only screen and (max-height: 259px) and (min-height: 240px) {
  .Auth-pages-container,.Auth-pages-container-complete-profile {
    height: 220%;
  }
}
@media only screen and (max-height: 239px) and (min-height: 210px) {
  .Auth-pages-container,.Auth-pages-container-complete-profile {
    height: 250%;
  }
}
@media only screen and (max-height: 209px) and (min-height: 200px) {
  .Auth-pages-container,.Auth-pages-container-complete-profile {
    height: 260%;
  }
}
.Auth-pages-top-bar {
  width: 100%;
  height: 51px;
  background-color: rgba(90, 90, 90, 0.2);
  position: absolute;
}

.Auth-pages-top-bar-logo {
  width: 200px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  background-image: url("../logo1.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.Auth-pages-form-container {
  background: #10101017;
  width: 75%;
  height: 85%;

  top: 11%;
  left: 13%;
  position: absolute;
  border-radius: 5px;
}

.Auth-pages-form-container-complete-profile {
  background: #10101017;
  width: 60%;
  height: 80%;

  top: 15%;
  position: absolute;
  border-radius: 5px;
}

.Auth-pages-form-left-desktop {
  width: 50%;
  height: 100%;
  position: absolute;
  border-radius: 5px;

  background: #00000071;
}

.Auth-pages-form-left-desktop-complete-profile {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 5px;

  background: #00000071;
}

.Auth-pages-form-right-desktop {
  display: flex;
  width: 50%;
  height: 100%;
  left: 50%;
  border-radius: 5px;

  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../log.jpg");
}

.Auth-pages-form-right-info-desktop {
  display: flex;
  width: 100%;
  height: 100%;

  border-radius: 5px;
  background: #00000086;

  position: absolute;
}

.Auth-pages-left-top-text-container-desktop {
  position: absolute;
  width: 100%;
  height: 30px;
  top: 30px;
}

.Auth-pages-left-top-text {
  position: absolute;
  width: auto;
  height: auto;
  bottom: 5px;
  left: 25px;
  color: #f2f2f2;
  opacity: 1;
  font-size: 2rem;
  font-family: "Nunito Sans", sans-serif;
}
.Auth-pages-left-top-text-complete-profile {
  width: 100%;
  padding-top: 20px;
  height: auto;
  bottom: 5px;
  color: #f2f2f2;
  opacity: 1;
  font-size: 1.6rem;
  font-weight: 100;
  display: flex;
  justify-content: center;
}

.Auth-pages-left-top-text-info-container {
  position: absolute;
  width: 100%;
  top: 50px;
}

.Auth-pages-left-top-text-info {
  position: absolute;
  width: auto;
  height: auto;
  bottom: 0;
  left: 25px;
  color: #f2f2f2;
  opacity: 0.35;
  font-size: 11px;
  font-family: "Nunito Sans", sans-serif;
}
.Auth-pages-left-inputs-container {
  width: 93%;
  height: 55%;
  position: absolute;
  top: 20%;
  left: 25px;
}
.Auth-pages-left-bottom-container {
  width: 93%;
  height: 15%;
  position: absolute;
  bottom: 0px;
  left: 25px;
}

.top-input-email,
.top-input-username,
.top-input-firstName,
.top-input-phone_number,
.top-input-password,
.top-input-country,
.top-input-city {
  border: none;
  border-bottom: 0.4px solid rgba(107, 107, 107, 0.692);
  background: none;
  color: #fff;
  width: 95%;
  outline: none;
  font-size: 0.7rem;
}
.top-input-img{
  display: flex;
  border: none;
  border-bottom: 0.4px solid rgba(107, 107, 107, 0.692);
  background: none;
  color: #fff;
  width: 95%;
  outline: none;
  font-size: 0.7rem;
  border-radius: 0px !important;
}
.containerEmail,
.containerDateOfBirth,
.container-Username,
.containerFirstName,
.containerLastName,
.container-phone,
.container-password,
.container-password-confirm-password,
.container-country,
.container-city {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.containercapcha {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 65%;
  margin-top: -25px;
  transform: scale(0.6) !important;
}
@media only screen and (min-width: 500px) and (max-width: 600px) {
  .containercapcha {
    width: 100%;
  }
}
@media only screen and (min-width: 601px) and (max-width: 700px) {
  .containercapcha {
    width: 140%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1340px) {
  .containercapcha {
    width: 56%;
  }
}

@media only screen and (min-width: 1341px) and (max-width: 1439px) {
  .containercapcha {
    width: 50%;
  }
}
@media only screen and (min-width: 1440px) and (max-width: 1700px) {
  .containercapcha {
    width: 50% !important;
    transform: scale(0.7) !important;
  }
}

@media only screen and (min-width: 1701px) and (max-width: 1800px) {
  .containercapcha {
    transform: scale(0.7) !important;
  }
}
@media(min-width:1801px){
  .containercapcha {
    width: 100% !important;
    transform: scale(0.7) !important;
  }
}

.icon,
.icon-user,
.icon-user-firstName,
.icon-phone,
.icon-password,
.icon-country,
.icon-city {
  color: #a7a7a78e;
  position: absolute;
  left: 90%;
}
.bottom-check-container {
  position: absolute;
  width: 115px;
  height: 8%;
  right: 20px;
}
.bottom-check {
  position: absolute;
  left: -20px;
  bottom: 0px;
  cursor: pointer;
}

.check-text {
  font-size: 9.8px;
  color: #f2f2f2;
  opacity: 0.85;
  font-family: "Nunito Sans", sans-serif;
  position: absolute;
  right: 0px;
  bottom: 0px;
}
@media only screen and (max-width: 1500px) and (min-width: 1350px) {
  .bottom-check-container {
    width: 120px;
    right: 30px;
  }
}
@media only screen and (max-width: 780px) and (min-width: 601px) {
  .bottom-check-container {
    width: 100px;
    right: -20px;
  }
  .check-text {
    top: 0px;
  }
}
@media only screen and (max-width: 600px) and (min-width: 320px) {
  .Auth-pages-left-bottom-container {
    height: 10%;
  }
  /* .container-btns {
    margin-top: 30px !important;
  } */
}

.container-btns {
  width: 100%;
  /* margin-top: 19px;
  margin-bottom: 10px; */
  display: flex;
  justify-content: space-around;
}

.containerForgotPassword {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.remember {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.rememberme {
  font-size: 0.7rem;
  padding-left: 5px;
  color: rgb(162 162 162);
}
.forgotPassword {
  color: rgb(162 162 162);
  font-size: 0.7rem;
  width: 80px;
  padding-top: 5px;
}
.forgotPassword:hover {
  color: white;
}
@media (min-width: 425px) {
  .forgotPassword {
    width: 110px;
  }
  .containerForgotPassword {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}
@media only screen and (min-width: 601px) and (max-width: 700px) {
  .forgotPassword {
    width: 80px !important;
  }
  .containerForgotPassword {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
}

.signUp {
  width: 95% !important;
  height: 35px !important;
  bottom: -15% !important;
  margin-top: -10px !important;
  background-color: #286efa;
  border: none !important;
  border-radius: 100px !important;
  color: #fff !important;
  font-family: "Nunito Sans", sans-serif;
  cursor: pointer !important;
}

.signUpConfirmCode {
  width: 95% !important;
  height: 35px !important;
  bottom: -15% !important;
  margin-top: 40px !important;
  background-color: #286efa;
  border: none !important;
  border-radius: 100px !important;
  color: #fff !important;
  font-family: "Nunito Sans", sans-serif;
  cursor: pointer !important;
}
.signUp-small-width {
  width: 100px;
  height: 30px;
  bottom: -15%;
  background-color: #286efa;
  border: none;
  border-radius: 100px;
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  cursor: pointer;
}
.next {
  width: 42% !important;
  height: 35px !important;
  bottom: -15% !important;
  margin-top: 40px !important;
  background-color: #286efa;
  border: none !important;
  border-radius: 100px !important;
  color: #fff !important;
  font-family: "Nunito Sans", sans-serif;
  cursor: pointer !important;
}
.backbtn {
  width: 42% !important;
  height: 35px !important;
  bottom: -15% !important;
  margin-top: 40px !important;
  background-color: transparent !important;
  border: 1px solid #286efa !important;
  border-radius: 100px !important;
  color: #286efa !important;
  font-family: "Nunito Sans", sans-serif;
  cursor: pointer !important;
}
.containerbtns {
  display: flex;
  justify-content: space-around;
}
.btn-to2 {
  width: 80px;
  height: 30px;
  bottom: -15%;
  background-color: transparent;
  border: 0.3px solid #aaaaaa;
  border-radius: 34px;
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  cursor: pointer;
}

.bottom-info {
  width: auto;
  position: absolute;
  top: 15px;
  color: rgba(194, 191, 191, 0.671);
  font-family: "Nunito Sans", sans-serif;
  font-size: 12px;
}

@media only screen and (max-width: 767px) and (min-width: 601px) {
  .bottom-info {
    display: none;
  }
  .Auth-pages-left-bottom-container {
    width: 65%;
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .bottom-info {
    display: none;
  }
  .Auth-pages-left-bottom-container {
    width: 72%;
  }
}
@media only screen and (max-width: 717px) and (min-width: 601px) {
  .Auth-pages-left-top-text-info {
    bottom: -10px;
  }
}
@media only screen and (max-width: 358px) and (min-width: 320px) {
  .Auth-pages-left-top-text-info {
    bottom: -10px;
  }
}
.facebook {
  border-radius: 100px;

  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 110px;
  font-size: 12px;
  background: #3e5c9a;
  color: #fff;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  justify-content: center;
  align-items: center;
}

.google {
  border-radius: 100px;
  color: #fff;
  text-align: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 75px;
  font-size: 12px;
  background: #df4b38;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  justify-content: center;
  align-items: center;
}

.apple {
  border-radius: 100px;
  text-align: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 40px;
  font-size: 12px;
  background: #f2f2f2;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  justify-content: center;
  align-items: center;
}

.amazon {
  border-radius: 100px;
  color: #fff;
  text-align: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 5px;
  font-size: 12px;
  background: #ff9900;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex; /* NEW, Spec - Firefox, Chrome, Opera */
  justify-content: center;
  align-items: center;
}

.Auth-pages-form-right-top-txt {
  position: absolute;
  color: #fff;
  top: 100px;
  width: 100%;
  font-family: "Nunito Sans", sans-serif;
  font-size: 2.6rem;
  text-align: center;
}

.Auth-pages-form-right-info {
  position: absolute;
  color: #fff;
  top: 200px;
  width: 100%;
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  text-align: center;
}

.btn-to {
  width: 100px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 350px;
  background-color: transparent;
  border: 0.3px solid #aaaaaa;
  border-radius: 34px;
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  cursor: pointer;
}
/*select*/
select {
  color: white;
}
select:focus {
  background-color: #331853;
}

.labelEmail,
.labelCountry,
.labelPassword,
.labelConfirmPassword,
.labelUsername,
.labelLastname,
.Labelcity,
.labelFirstname {
  color: white;
  font-size: 0.8rem;
}

.container-1,
.container-2,
.container-3,
.container-3-Complete-profile {
  display: flex;
  padding-top: 20px;
}

@media only screen and (max-width: 1023px) and (min-width: 701px) {
  .container-3 {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }
}
.containerTop {
  display: flex;
  justify-content: center;
  height: 6px;
}
.content1 {
  width: 45%;
  border-radius: 15px;
  background-color: rgb(131 131 131 / 70%);
}
.content1Page2 {
  width: 45%;
  border-radius: 15px;
  background-color: rgb(131 131 131 / 30%);
}
.content2 {
  width: 45%;
  border-radius: 15px;
  background-color: rgb(131 131 131 / 30%);
}
.content2Page2 {
  width: 45%;
  border-radius: 15px;
  background-color: rgb(131 131 131 / 70%);
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .container-1,
  .container-2,
  .container-3 {
    display: flex;
    padding-top: 0px;
  }
  .Auth-pages-form-container-complete-profile {
    width: 90%;
  }
}
@media only screen and (max-width: 600px) and (min-width: 430px) {
  .Auth-pages-left-top-text-complete-profile {
    font-size: 1.3rem;
  }
}
@media only screen and (max-width: 429px) and (min-width: 320px) {
  .Auth-pages-left-top-text-complete-profile {
    font-size: 1rem;
  }
}
.containerImg {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}
.chooseimg {
  color: white;
  font-size: 1rem;
  padding-top: 10px;
}
.imgStyles {
  width: 6rem;
  height: 6rem;
  border-radius: 80px;
}
.pluscircle {
  width: 20px;
  height: 20px;
  margin-left: 70px;
  margin-top: -25px;
  background-color: #286efa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-plus:before {
  content: "\f067";
  color: white ;
  font-size: 0.8rem;
}

.fa-plus1:before {
  content: "\f067";
  color: #767676 ;
  font-size: 0.8rem;
}

.file-upload-wrapper2 {
  width: 20px;
  height: 20px;
  margin-left: 20px;
  margin-top: -18px;
  border-radius: 10px;

 }
  .file-upload-wrapper2:after {
    content: attr(data-text);
    margin-left: 20px;
  margin-top: -18px;
  border-radius: 10px;

    padding: 10px 15px;
    display: block;
    width: calc(20px);
    pointer-events: none;
    z-index: 20;
    height: 20px;
    line-height: 30px;
    color: transparent;
    font-weight: 500;
    overflow: hidden; }
  .file-upload-wrapper2:before {
    content: '';
    margin-left: 20px;
  margin-top: -18px;
  border-radius: 10px;

    display: inline-block;
    height: 20px;
    width: 20px;
    background-color: transparent;
    color: transparent;
    font-weight: 500;
    z-index: 25;
    font-size: 16px;
    line-height: 50px;
    padding: 0 15px;
    text-transform: capitalize;
    pointer-events: none;
     border-radius: 10px;  
  }
  .file-upload-wrapper2:hover:before {
    background-color: transparent;
  }
  .file-upload-wrapper2 input {
    opacity: 0;
    margin-left: 20px;
  margin-top: -30px;
  border-radius: 10px;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 30px;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 30px;
   }

   .responsiveLoginBtns{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
   }

   .termsHover:hover{
    color: #286efa;
    cursor: pointer;
   }