.friends-list {
  padding-left: 0;
  background-color: transparent;
}

.title-total-peoples {
  font-size: 14px;
  color: #fff;
  margin: 10px;
}

.friends-item-list {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;
  background-color: transparent;
  border-radius: 5px;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ffffff0d;
}

.friends-item-list-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.friends-item-list-data-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.friends-item-list-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #5c5c5c1c;
  border: 1px solid #5c5c5c41;
  margin-right: 10px;
}

.fas:hover {
  color: white;
  transition: 0.5s ease;
}

.friends-item-list-name {
  color: white;
  font-size: 16px;
}

.friends-item-list-status {
  display: flex;
  color: rgba(206, 206, 206, 0.747);
  font-size: 13px;
}

.friends-item-list-button-invite {
  width: auto;
  height: 30px;
  border-radius: 14px;
  background-color: #f2f2f225;
  color: #767676;
  font-weight: 100;
  font-size: 0.7rem;
  padding-right: 17px;
  padding-left: 17px;
  border: 0px;
  margin-right: 10px;
}

.friends-item-list-button-invite:hover {
  color: white;
  transition: 0.5s ease;
}

.fas {
  color: #767676;
}

.friending-navbar {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 35vw;
  min-width: 350px;
  justify-content: space-between;
  margin: 10px;
  background-color: transparent;
  border-radius: 5px;
  padding: 10px;
}

.friending-navbar a {
  color: #767676;
  display: inline-block;
  transition: 0.5s ease;
}

.friending-navbar a:hover {
  color: white;
}

.friending-navbar .active {
  color: white;
  border-bottom: 1px solid white;
  border-radius: 1;
  transition: 0.5s ease;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;
  background: #5c5c5c1c;
  border: 1px solid #5c5c5c41;
  border-radius: 14px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
}

.search-bar input {
  width: 100%;
  border: 0px;
  border-radius: 5px;
  padding: 5px;
  background-color: transparent;
  color: #767676;
  font-size: 16px;
}

.search-bar input:focus {
  outline: none;
}

.search-button {
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: transparent;
  border: 0px;
  color: white;
  align-items: center;
  justify-content: center;
}

.itemSelected {
  color: white;
  text-align: left;
  font-size: 1.125rem;
  font-weight: 500;
  font-family: sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
}

.itemNoSelected {
  text-align: left;
  font-size: 1.125rem;
  font-weight: 500;
  font-family: sans-serif;
  letter-spacing: 0px;
  color: #f2f2f25a;
  opacity: 1;
  cursor: pointer;
}

.hidden {
  display: none;
}
.show {
  display: block;
}
.tab-head {
  border-bottom: 1px solid #ccc;
}
.tabTitles {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.tabTitles li {
  list-style: none;
  display: flex;
  border-bottom: 0.5px solid #f2f2f22f;
  margin-bottom: -1px;
}
.tabTitles li.show {
  border-bottom: 2px solid white;
  margin-bottom: -1px;
}
.tabTitles li a {
  color: #f2f2f25a;
  text-decoration: none;
  margin: 0.7rem 2rem;
  cursor: pointer;
}
.tabTitles li.show:hover a {
  color: white;
}
.tabTitles li.show a {
  color: white;
}
.tab-body {
  padding: 0.5rem;
}

.options-total-peoples {
  margin-top: 1rem;
}

.options-total-peoples a:hover {
  color: white !important;
}

@media only screen and (max-width: 538px) and (min-width: 442px) {
  .tabTitles li a {
    margin: 0.7rem 1rem !important;
  }
}

@media only screen and (max-width: 441px) and (min-width: 394px) {
  .tabTitles li a {
    margin: 0.7rem 0.5rem !important;
  }
}

@media only screen and (max-width: 393px) and (min-width: 320px) {
  .tabTitles li a {
    margin: 0.7rem 0.3rem !important;
  }

  .itemSelected,
  .itemNoSelected {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .friends-item-list-name {
    color: white;
    font-size: 0.8rem;
  }
}
