.container-section-search {
  height: 80px;
  display: flex;
  padding: 0;
}
.containerInputSearch {
  display: flex !important;
  width: 100%;
  justify-content: flex-start;
  align-items: center;

  height: 70px;
  background: rgba(65, 64, 64, 0.164);
}
.fa-search:before {
  content: "\f002";
  margin-left: 50px;
}
.fa-search:hover{
  color: #c5c0c0;
}
.SearchInput {
  width: 100%;
  background: transparent;
  background-color: none;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  margin-left: 10px;
  outline: none;
  color: white;
}
.buttons-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.color-icon1{
  margin-right: 10px;
  color: #ffffff;
}
.color-icon2 {
  color: white;
  margin-right: 10px;
}
.color-icon1-active,.color-icon2-active{
  margin-right: 10px;
  color: #0d6efd;

}
.color-icon1:hover{
  color: #0d6efd;
}
.color-icon2:hover {
  color: #0d6efd;
}

.btn-all-challange {
  margin-right: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 106px;
  font-size: 13px;
}

.p-styles {
  margin-left: 30px;
  color: #999898;
  margin-top: 0px;
  font-size: 3vh;
}
.h1styles {
  margin-left: 30px;
  color: white;
  font-size: 3.5vh;
  padding-bottom: 20px;
}

/*cards*/

.container-all-cards {
  display: flex;

  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}
.container-all-cards-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}


.cardstyles {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
}

.shadowCard {
  padding: 10px;
  margin-top: 20px;
  /*  display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
}
.shadowCard2 {
  padding: 10px;
  margin-top: 20px;
  width: 50rem;
  /*  display: flex;
  flex-direction: row;
  flex-wrap: wrap; */
}
@media only screen and (min-width:1701px) and (max-width:2000px){
  .shadowCard2 {
  width: 40rem;
    }
  } 
 @media only screen and (min-width:1300px) and (max-width:1700px){
.shadowCard2 {
width: 35rem;
  }
} 
@media only screen and (min-width:471px) and (max-width:1299px){
  .shadowCard2 {
  width: 30rem;
    }
  } 
@media only screen and (min-width:400px) and (max-width:470px){
  .shadowCard,.shadowCard2 {
width: 25rem;
  }
}
@media only screen and (min-width:350px) and (max-width:399px){
  .shadowCard,.shadowCard2 {
width:22rem;
  }
}
@media only screen and (min-width:320px) and (max-width:349px){
  .shadowCard,.shadowCard2 {
width:20rem;
  }
}
@media only screen and (min-width:320px) and (max-width:575px){
  .color-icon1,.color-icon2{
    margin-right: 10px;
    color: #ffffff;
    display: none;
  }
  
  .color-icon1-active,.color-icon2-active{
    margin-right: 10px;
    color: #0d6efd;
    display: none;
  
  }
}

.stylesCard {
  background-color: rgb(34, 34, 33);
  display: flex;
  /* flex-direction: column;
   flex-wrap: wrap; */
}
.container-img-title-subtitle {
  display: flex;
}
.imgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.imgCard {
  padding: 0px 10px 10px;
  margin-top: -25px;
  width: 17vh;
  height: 15vh;
  border-radius: 16px;
}
.btn-img {
  margin-top: -3vh;
  width: 10vh;
  font-size: 0.7rem;
  border-radius: 5px;
  color: #fff;
  background-color: #0d6efd;
  border: none;
  font-size: 2.3vh;
}
.containerTitle-Subtitle {
  flex-direction: row;
}
.titleContainer {
  color: white;
  padding-top: 3vh;
}
.title1 {
  font-size: 3vh;
}
.subtitleContainer {
  color: #f2f2f2;
}
.role {
  font-size: 2vh;
}
.styles-ellipsis-v {
  color: #726d6d;
  padding-right: 10px;
  padding-top: 10px;
}
.styles-ellipsis-v:hover {
  color: white;
}
.container-icons-Card {
  display: flex;
  justify-content: space-around;
  height: 8vh;
  align-items: center;
  border-bottom: 2px solid #f2f2f21a;
}
.nft-txt-btl{
  font-weight: normal;
    font-family: monospace;
}
.nft-card-bt-cont-i-i,
.nft-card-bt-cont-i-i-r {
  padding: 5px;
  width: 7rem;
  display: flex;
  justify-content: center;
  border: 1px solid #f2f2f21a;
  border-radius: 10px;
  background-color: rgb(31, 30, 30);
}
.container-p-and-button {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.p-description {
  color: white;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 0.8rem;
}
.container-p {
  display: flex;
  flex-direction: column;
  width: 40vh;
}
.container-button {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-content: center;
  padding-right: 5px;
  padding-bottom: 10px;
}
.btn-see-challenge{
  width: 6rem;
  height: 30px;
  font-size: 12px;
  border-radius: 5px;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  padding: 0;
  margin: 0;
}

/*cards*/
.container-icon-input{
  display: flex;
  width: 100%;
  }
  .iconSearch {
    padding-top: 5px;
  }
@media (max-width: 425px) {
  .containerInputSearch {
    flex-direction: column;
    align-items: baseline;
  }

  .container-icon-input{
  display: flex;
  }
  .iconSearch {
    padding-top: 5px;
  }
  .fa-search:before {
    content: "\f002";
    margin-left: 40px;
  }
  .fa-search:hover{
    color: #c5c0c0;
  }

  .buttons-section {
    display: flex;
    padding-left: 10px;
  }
}
.custom-container2Home, .custom-containerHome{
  height: auto;
  background-color: black;
} 
@media (min-width: 992px) {
  .custom-container {
    margin-left: 230px;
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;
    height: 100vh;
  }

  .custom-container2 {
    margin-left: 64px;
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;
    height: 100vh;
  }

  .custom-containerHome {
    margin-left: 230px;
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;
    background-color: black;
    /* height: 100vh; */
    height: auto;
  }

  .custom-container2Home {
    margin-left: 64px;
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;
    background-color: black;
    /* height: 100vh; */
    height: auto;
  }
}

@media (max-width: 575px) {
  .container-all-cards{
    display: flex;
    justify-content: center;

  }
}
@media(max-width:330){
  .card{
    max-width: 19rem;
  }
}

.noChallenges{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  color: white;
  font-size: 1rem;
}

.containerChallenges{
  padding-left: 1rem;
  padding-right: 1rem;
}

.colorwhite{
  color: white;
}

.colorwhite:hover{
  color: wheat;
}

.iconSearch{
  margin: 1.5rem;
  color: white;
}