@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
body,
.all-bg {
  background: rgba(0, 0, 0, 0.705);
}

.custom-container, .custom-containerHome {
  width: auto;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-container::-webkit-scrollbar {
  display: none;
}
.custom-containerHome::-webkit-scrollbar {
  display: none;
}

.custom-container2, .custom-container2Home {
  width: auto;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-container2::-webkit-scrollbar {
  display: none;
}
.custom-container2Home::-webkit-scrollbar {
  display: none;
}
.top-txt {
  color: #ffffff7c;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-size: 18px;
  font-weight: 550;
}

.custom-items-container {
  height: calc(100vh - 127px);

  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.custom-items-container::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-height: 470px) and (min-height: 400px) {
  .custom-items-container {
    height: calc(150vh);
   }
}

@media only screen and (max-height: 720px) and (min-height: 471px) {
  .custom-items-container {
    height: calc(125vh);
   }
}

.custom-title {
  margin-left: 15px;
}

@media only screen and (max-width: 1366px) and (min-width: 768px) { 
  .custom-image-container {
   height: 200px !important; 
}
}
@media only screen and (max-width: 767px) and (min-width: 320px) { 
 .custom-image-platforms-container{
  background: rgba(0, 0, 0, 0.705);
} 
.custom-txt-container{
  height: 38px !important;
}
}

.bt-container {
  height: 65px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.custom-bt {
  background: transparent linear-gradient(90deg, #286efa 0%, #4b86fa 100%) 0% 0%
    no-repeat padding-box;
  border: none;
  border-radius: 5px;
  height: 36px;
  width: 138px;
  color: rgba(255, 255, 255, 0.842);
  font-size: 12px;
  font-weight: 500;
}

.custom-bt:hover {
  background: #3678fa;
  color: rgb(255, 255, 255);
}

.custom-txt-container {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;

  font-size: 24px;
  letter-spacing: 0.5px;
  color: #f2f2f2;
  opacity: 0.7;
}

.custom-select-container {
  height: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.custom-select {
  height: 40px;
  width: 108px;

  margin-right: 20px;
  border-radius: 5px;
  background: transparent;
  color: rgba(255, 255, 255, 0.815);
  text-align: center;
  font-size: 13px;
  border-color: rgba(189, 189, 189, 0.452);
}

.custom-select:hover {
  color: rgb(255, 255, 255);
}

.custom-image-container {
  height: 150px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.custom-image-block {
  height: 47px;
  width: 47px;
  margin-right: 15px;
  border-radius: 5px;
}
.custom-image {
  height: 47px;
  width: 47px;
  border-radius: 5px;
  padding-bottom: 2px;
}

.custom-image-platforms-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.custom-game-card {
  width: 240px;
  height: 240px;
  border-radius: 10px;

  margin-top: 20px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 20px;
  position: relative;
}
.custom-game-card .custom-game-card-info {
  position: absolute;
  z-index: 999;
  text-align: center;
  bottom: 0;
  left: 0;

  background: transparent
    linear-gradient(56deg, #231b4d 0%, #24071b 64%, #100e2c 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 10px 150px #0000004d;
  opacity: 0.9;
  width: 100%; /* Set the width of the positioned div */
  height: 85px;
}
.custom-game-image {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 10%;
  position: relative;
  margin-bottom: 20px;
}
.custom-game-card-info {
  padding: 10px;
  background: transparent linear-gradient(58deg, #231B4D 0%, #24071B 64%, #100E2C 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  border-radius: 20px;
  text-align: center;
}
.custom-game-card-info-title {
 font-family: sfPro;
 font-weight: 500;
}

.custom-game-card-info-bt {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.custom-game-card-info-platform {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.custom-game-card-info-platform-participants {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 55px;
  justify-content: center;
}

.card-bt {
  background: #286efa;
  color: #fff !important;
  width: 80px;
  height: 30px;
  font-size: 13px;
  margin-right: 10px;
  border-radius: 10px;
  font-family: 'sfPro';
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
}

.card-bt:hover {
  background: #3b7bfc;
}
.card-txt {
  color: #fff;
  font-size: 14.5px;
}

.card-date {
  margin-left: 15px;
  font-size: 11px;
  color: #fff;

  font-weight: 500;
}

.card-platform {
  width: 28px;
  height: 28px;
  border-radius: 100px;
  margin-left: 15px;
  margin-bottom: 10px;
}

.card-part { 
  display: flex;
  font-size: 12px;
  margin-right: auto;
  flex-direction: row;
  margin-bottom: 10px;
}

.num-participants {
  font-size: 12px;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  color: azure;
}
.participants{
  font-size: 12px;
  color: #5d7386;
  opacity: 0.85;
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
}

@media (min-width: 992px) {
  .custom-container {
    margin-left: 230px;
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;

    height: 100vh
  }

  .custom-container2 {
    margin-left: 64px;
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;

    height: 100vh
  }
}


.containerCardsHomePage{
  height: 100vh;
  overflow: scroll;
  width: 100%;
}

.containerCardsHomePage::-webkit-scrollbar{
  display: none;
}

.styleContainer{
  display: flex;
  flex-wrap: wrap;
}

.containerTermsAndConditions{
  background-color: white;
  height: 100vh !important;
  padding: 2rem !important;
  overflow: scroll;
}

.return{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.return:hover{
  color:#286efa;
}