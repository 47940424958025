.top-bar {
  background: rgba(16, 16, 16, 255);
  position: fixed;
  width: 100%;
  z-index: 9999999;
  border-bottom: 2px solid #555;
}

.top-bar-left-button {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #17151b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
}

.top-bar-center-logo {
  width: 225px;
  height: 55px;
  background: url(../logo1.png);
  position: absolute;

  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-size: cover;
}

.top-bar-left-button {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background: #17151b;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-bar-icon {
  color: #454341;
  display: flex;
}

.Circle1 {
  padding-top: -10px;
  color: white;
  background-color: red;
  font-size: 0.6rem;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40%;
  width: 0.6rem;
  height: 0.6rem;
}

.left-container {
  margin-top: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.center-container {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #434145;
}

.rigth-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1230px) and (min-width: 992px) {
  .top-bar-rigth-button-text {
    display: none !important;
  }
}

@media only screen and (max-width: 425px) and (min-width: 320px) {
  .top-bar-rigth-button-text {
    display: none !important;
  }
}

.top-bar-rigth-button {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
}

.top-bar-rigth-button-icon {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #181718;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar-rigth-button-image {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background: #8b8b8b9f;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar-rigth-button-text {
  width: auto;
  height: auto;
  margin-right: 5px;
  color: #B9B9B9;
  font-size: 14px;
  font-family: sfPro;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}

.top-bar-rigth-button-info {
  width: auto;
  height: 32px;
  color: #B9B9B9;
  font-family: sfPro;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar-icon-info {
  color: #434145;
  margin-right: 5px;
  font-size: 17px;
}

.top-bar-icon:hover {
  color: #ffffff;
}

.cont {
  position: relative;
}

.DropdownTogglestyles {
  background-color: transparent !important;
  border: transparent !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.ChangePasswordItem {
  color: rgb(255, 255, 255) !important;
  font-size: 0.8rem;
  font-family: unset !important;
  font-weight: 600 !important;
}

.ChangePasswordItem:hover {
  color: rgba(255, 255, 255, 0.822) !important;
}

.dropdown-menu.show {
  display: block;
  background-color: #101010 !important;
}

.colormenu {
  background-color: #101010 !important;
}

.modal {
  background: #212121;
  position: fixed;
  top: 26px;
  left: 72%;
  width: 90%;
  max-width: 513px;
  height: auto;
  max-height: calc(100vh - 56px);
  overflow-y: auto;
  align-items: flex-start;
  opacity: 1;
}

@media (max-width: 1800px) {
  .modal {
    left: 67%;
    max-height: calc(100vh - 100px);
  }
}

@media (min-width: 769) {
  .modal {
    left: 20%;
    max-height: calc(100vh - 100px);
  }
}

@media (max-width: 768px) {
  .modal {
    top: -100px;
    left: 35%;
    max-height: calc(100vh - 100px);
  }
}

@media (max-width: 1571px) {
  .modal {
    top: 25px;
    left: 65%;
    max-height: calc(100vh - 100px);
  }
}
@media (max-width: 1470px) {
  .modal {
    top: 25px;
    left: 62%;
    max-height: calc(100vh - 100px);
  }
}

@media (max-width: 1350px) {
  .modal {
    top: 25px;
    left: 50%;
    max-height: calc(100vh - 100px);
  }
}
@media (max-width: 1025px) {
  .modal {
    top: 25px;
    left: 40%;
    max-height: calc(100vh - 100px);
  }
}
@media (max-width: 855px) {
  .modal {
    top: 25px;
    left: 30%;
    max-height: calc(100vh - 100px);
  }
}
@media (max-width: 735px) {
  .modal {
    top: 25px;
    left: 10%;
    max-height: calc(100vh - 100px);
  }

  .modal-option {
    font-size: 14px;
  }
  .modal-header {
    font-size: 28px;
  }
  .modal-button {
    width: 130px;
    max-width: 150px;
  }
  .wallet-address, .wallet-balance {
    font-size: 16px;
  }
}

.modal-body {
  background-color: #212121;
  padding: 10px;
}

.modal-option {
  /* padding: 10px; */
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  padding: 12px;
}

.modal-option:hover {
  background-color: #474747;
}

.modal-option.selected {
  background-color: #474747;
}

.modal-text {
  text-align: left;
  font: normal normal medium 20px/36px SF UI Display;
  letter-spacing: 0px;
  color: #767676;
  background-color: #212121;
  padding: 20px;
}

.modal-header {
  padding: 20px;
  margin-top: 31px;
  text-align: left;
  font: normal normal medium 32px/36px SF UI Display;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  background-color: #212121;
}

.modal-button {
  width: 150px;
  height: 40px;
  background: #d20056;
  color: white;
  border-radius: 30px;
  margin-bottom: 20px;
  margin-left: auto;
  font-family: "sfPro";
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.modal-option span {
  margin-left: 10px;
}

.modal-option img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 10px;
}

.connect-button {
  width: 150px;
  height: 40px;
  background: #d20056;
  color: white;
  border-radius: 30px;
  margin-bottom: 20px;
  margin-left: auto;
  font-family: "sfPro";
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.address-balance-wrapper {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}

.wallet-address {
  color: #757C8E;
  font-size: 18px;
  font-family: sfPro;
  cursor: pointer;
  background-color: #0E1118;
  margin-right: 20px;
}

.wallet-balance {
  color: #8B8C8E;
  font-size: 18px;
  font-family: sfPro;
  cursor: pointer;
  background-color: #1B1E22;
}

.wallet-data:hover {
  text-decoration: underline;
}

.wallet-address.success {
  color: green;
}

.clipboard-icon {
  margin-right: 4px;
}