.containerModal {
  display: flex;
  position: absolute;
  right: 1%;
  z-index: 99999;
  top: 20px;
  max-height: 400px;
}
.bodyScroll{
  overflow: scroll;
}
.bodyScroll::-webkit-scrollbar{
  display: none;
}
.titleNotifications {
  font-size: 1em;
  color: white;
}
.containerNotification {
  display: flex;
  flex-direction: column;
  background-color: rgb(54 53 53 / 25%);
  padding: 5px;
  margin: 5px;
  align-items: flex-start;
  border-radius: 10px;
}
.Container-img-email {
  display: flex;
  align-items: center;
}
.email {
  margin-left: 10px;
  color: #f5f8f5e3;
  font-size: 0.9rem;
  font-weight: 500;
}
.stylesp{
  font-size: 0.7rem;
  color: #f5f8f5e3;
  margin-left: 10px;
}
.Container-email-stylesp{
  display: flex;
  flex-direction: column;
}
.modal-content {
  background-color: rgb(3 7 9 / 99%);
  border: 1px solid rgb(198 182 182 / 20%);
}
.modal-header {
  border-bottom: 1px solid #dee2e61c;
  justify-content: center;
}
.modal-footer {
  border-top: 1px solid #dee2e61a;
  justify-content: center;
}
.photoUser {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.btnClose {
  width: 70px;
  height: 29px;
  font-size: 14px;
  border-radius: 5px;
  color: #fff;
  background-color: #101214;
  border-color: #9f9f9f;
  padding: 0;
  margin: 0;
}

.Container-Buttons {
  margin: 10px;
  display: flex;
  margin-left: 50px;
}
.container-bt-confirm {
  margin-right: 5px;
  display: flex;
}
.Confirm {
  padding-right: 5px !important;
}
.Delete {
  padding-left: 5px !important;
}
.Confirm,
.Delete {
  padding: 0 !important;
  font-size: 0.9rem;
  margin-top: -1rem;
}
@media only screen and (max-width: 991px) and (min-width: 320px) {
  .modal-content {
    margin-top: 12vh !important;
  }

   @media only screen and (max-width: 991px) and (min-width: 768px) {
     .rigth-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    } 
  } 
}
