@font-face {
  font-family: "sfPro";
  src: url("./assets/fonts/SFProDisplay-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: 'Dogica';
  src: url('../src//fonts/dogica.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DogicaBold';
  src: url('../src/fonts/dogicabold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DogicaPixel';
  src: url('../src/fonts/dogicapixel.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DogicaPixelBold';
  src: url('../src/fonts/dogicapixelbold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "sfPro";
  src: url("./assets/fonts/SFProDisplay-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "sfPro";
  src: url("./assets/fonts/SFProDisplay-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "sfPro";
  src: url("./assets/fonts/SFProDisplay-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "sfPro";
  src: url("./assets/fonts/SFProDisplay-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "sfPro";
  src: url("./assets/fonts/SFProDisplay-Semibold.ttf");
  font-weight: 600;
}

$white: #fff;
$white2: #f2f2f2d9;
$shadow: #00000071;
$gray: rgba(242, 242, 242, 0.35);
$graytransparent: #bebebe;
$gray2: #f2f2f280;
$gray3: #767676;
$gray4: #8d8d8d;
$black3: #151717;
$blue: #286efa;
$black2: #030709fc;
$black: #000;
$black4: #0e1010;
$purple: #778beb;
$orange: #e15f41;
$ligthGreen: #7bed9f;
$lightBlue: #63cdda;
$pink: #d20056;

html,
body,
#root {
  height: 100%;
  background: linear-gradient(45deg,
      rgba(0, 0, 0, 0.788),
      rgba(0, 0, 0, 0.788));
  background-size: cover;
  background-position: center;
}

.swal-modal {
  background: $black;

  .swal-text {
    color: $white;
  }

  .swal-title {
    color: $white;
  }

  .swal-button {
    background: $blue;
  }

  .swal-footer {
    text-align: center;
  }
}

.swal-icon {
  width: 71px;
  height: 65px;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  margin: 20px auto;
}

.swal-icon--error__line {
  position: absolute;
  height: 5px;
  width: 40px;
  background-color: #f27474;
  display: block;
  top: 30px;
  border-radius: 2px;
}

.swal-icon--warning__body {
  width: 5px;
  height: 35px;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
}

.swal-icon--success__line--long {
  width: 40px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}

.swal-icon--success__line--tip {
  width: 20px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}

.swal-icon--success__ring {
  width: 71px;
  height: 65px;
  border: 4px solidhsla(98, 55%, 69%, 0.2);
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
}

.modal-content {
  background-color: $black2;
  border: 1px solid rgba(198, 182, 182, 0.2);

  .modal-header {
    border-bottom: 1px solid rgba(222, 226, 230, 0.10980392156862745);
    justify-content: center;
  }

  .modal-footer {
    border-top: 1px solid rgba(222, 226, 230, 0.10196078431372549);
    justify-content: center;
  }
}

.Auth-header1 {
  height: 60px;
  background-color: rgba(90, 90, 90, 0.2);
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 30px;

  .logo {
    width: 200px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
    background-image: url("./assets/logo1.png");
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.custom-container,
.custom-container2 {
  padding-top: 90px;
}

.custom-containerHome,
.custom-container2Home {
  padding-top: 60px;
}

.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 999999999;
  top: 0;
  background: $shadow;

  div {
    color: $white;
    text-align: center;
  }

  i {
    color: $white;
    font-size: 40px;
  }
}

.Auth {
  font-family: sfPro;

  .container {
    max-width: 1200px;
  }

  .title-signup {
    font-size: 1.6rem;
    font-family: sfPro;
    font-weight: bold;
    color: $white;
  }

  .dark-bg {
    background: $shadow;
    padding: 50px 65px 20px 35px;
  }

  .subtitle {
    color: $white;
    font-weight: 500;
    margin-top: 10px;
  }

  .captcha {
    transform: scale(0.7);
    margin-left: -100px;
  }

  .form-overlay {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 33.33%;
    max-width: 600px;
    background: linear-gradient(135deg, #302730, #5b5358);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 1000px) {
    .form-overlay {
      width: 70%;
    }
  }

  .bg-signup {
    background-image: linear-gradient(45deg, #00000099, #00000099),
      url("./assets/login.png");
    background-size: cover;
    background-position: center;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bg-signin {
    background-image: linear-gradient(45deg, #00000099, #00000099),
      url("./assets/login.png");
    background-size: cover;
    background-position: center;
  }

  .fields {
    position: relative;
    margin-bottom: 30px;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $white2;
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $white2;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $white2;
    }

    label {
      color: $white2;
    }

    .form-field {
      width: 100%;
      background: transparent;
      border: 0;
      border-bottom: 1px solid $graytransparent;
      padding: 10px 40px 10px 0;
      outline: none;
      color: $white2;
      font-weight: 500;

      option {
        color: $black;
        font-size: 14px;
      }
    }

    i {
      color: $white2;
      position: absolute;
      bottom: 15px;
      right: 30px;
      font-size: 18px;
      z-index: 9;
    }
  }

  .title {
    font-size: 40px;
    color: $white;
    font-weight: bold;
    text-align: center;
  }

  .description {
    font-weight: 600;
    font-size: 18px;
    color: $white;
    margin-top: 40px;
    text-align: center;
    margin-bottom: 100px;
  }

  .terms {
    margin-bottom: 30px;
    text-align: right;
  }

  .terms-label {
    color: $white;
    margin-left: 10px;
    font-weight: 300;
  }

  .socials {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;

    p {
      color: $white;
      font-size: 14px;
      margin-bottom: 0;
    }

    .space {
      width: 15px;
    }

    .icons {
      display: flex;
    }
  }
}

.line {
  height: 2px !important;
  background: $white;
  width: 65px;
  margin: 0 auto;
}

.btn--primary {
  width: 150px;
  height: 40px;
  border-radius: 34px;
  background: $blue;
  color: $white;
  font-weight: bold;
  font-family: sfPro;
  border: 0;
  font-size: 18px;
}

.btn--secondary {
  width: 100%;
  max-width: 150px;
  height: 45px;
  border-radius: 22px;
  background: rgb(43, 26, 104);
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn--secondary:hover {
  background: #671441;
}

.form-title {
  color: #ffffff;
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 15px;
}

.greyed-out {
  filter: grayscale(25%);
  opacity: 0.7;
}

.form-description {
  color: #b8b8b8;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.btn--primary2 {
  width: 130px;
  height: 45px;
  border-radius: 34px;
  background: $blue;
  color: $white;
  font-weight: bold;
  font-family: sfPro;
  border: 0;
  font-size: 18px;
}

.btn-transparent {
  background: transparent;
  width: 130px;
  height: 45px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 35px;
  outline: none;
  color: $white;
  border: 2px solid $gray;
}

.title {
  color: $white;
  font-family: sfPro;
  font-weight: bold;
  font-size: 24px;
}

.form {
  .fields {
    position: relative;
    margin-bottom: 30px;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $white2;
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $white2;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $white2;
    }

    label {
      color: $white2;
    }

    .form-field {
      width: 100%;
      background: transparent;
      border: 0;
      border-bottom: 1px solid $graytransparent;
      padding: 10px 40px 10px 0;
      outline: none;
      color: $white2;
      font-weight: 500;

      option {
        color: $black;
        font-size: 14px;
      }
    }

    i {
      color: $gray2;
      position: absolute;
      bottom: 15px;
      right: 30px;
      font-size: 18px;
      z-index: 9;
    }
  }
}

.main-bg,
.main-bg-2 {
  // height: calc(100vh - 60px);
  padding-top: 20px;
  // overflow-y: scroll;
}

.main-bg {
  padding-left: 230px;
}

.main-bg-2 {
  padding-left: 64px;
}

.dark--bg {
  background: $shadow;
  padding: 50px 65px 20px 35px;
}

.box-header {
  font-family: "sfPro";
  border: 1px solid #f2f2f224;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  color: $white;
  backdrop-filter: blur(31px);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  h3 {
    font-size: 22px;
    margin-bottom: 0;
  }
}

.live-chat {
  background: $black3;
  border-radius: 30px;
  margin-top: 30px;
  position: relative;
  padding: 20px 15px;
  min-height: 90vh;

  .header {
    font-family: "sfPro";
    color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    h3 {
      font-size: 16px;
      font-weight: 600;
    }

    .users-quantity {
      color: $gray4;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  .line {
    background: rgba(255, 255, 255, 0.05);
    width: 80%;
    margin: 0 auto;
    height: 1px;
    margin-bottom: 20px;
  }

  .messages {
    margin-bottom: 20px;
    height: 70vh;
    overflow-y: scroll;
    padding: 0 15px;
  }

  h3 {
    position: relative;
  }

  .user-profile {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 20px;
    border-width: 1px;
    border-style: solid;
  }

  .message-cont {
    display: flex;
    align-items: center;
    position: relative;
  }

  .content-LH,
  .content-RH {
    position: relative;
    color: $white;
    margin-bottom: 5px;
    margin-bottom: 40px;
    align-items: end;

    span {
      color: $purple;
      display: block;
      margin-right: 10px;
    }

    .buble {
      background: #1c1f1f;
      border: 1px solid #ffffff0d;
      padding: 10px 20px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      margin-right: 15px;
      word-break: break-word;
      max-width: 90%;
      min-width: 100px;

      p,
      .content {
        color: $gray4;
      }

      img {
        width: 100%;
        height: 200px;
        border-radius: 10px;
      }
    }

    .date {
      position: absolute;
      bottom: -20px;
      font-size: 12px;
      color: $gray4;
      right: 0;
      display: flex;
      width: 100%;
    }
  }

  .preview {
    margin-bottom: 10px;
    position: relative;

    img {
      width: 100px;
      max-width: 100%;
    }

    .close {
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }

  .content-RH .buble {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20px;
  }

  .color-purple {

    span,
    .user-profile {
      border-color: $purple;
      color: $purple;
    }
  }

  .color-orange {

    span,
    .user-profile {
      border-color: $orange;
      color: $orange;
    }
  }

  .color-ligthGreen {

    span,
    .user-profile {
      border-color: $ligthGreen;
      color: $ligthGreen;
    }
  }

  .color-ligthBlue {

    span,
    .user-profile {
      border-color: $lightBlue;
      color: $lightBlue;
    }
  }

  .chat-container {
    display: flex;
    padding: 0 15px;

    .input {
      background: #222626;
      border: 1px solid #ffffff0d;
      height: 48px;
      border-radius: 20px;
      margin: 0;
      width: 100%;
      padding-left: 15px;
      color: $gray4;
      outline: none;
      font-size: 14px;
    }
  }

  .challenge-popup {
    width: 150px;
    height: 40px;
    background: $pink;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 30px;
    margin-bottom: 20px;
    font-family: "sfPro";
  }

  .second-options {
    background: #292e2e;
    border: 1px solid #ffffff19;
    border-radius: 20px;
    padding: 20px;
    position: absolute;
    right: 20px;

    &::after {
      content: "";
      position: absolute;
      right: -10px;
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 20px solid #292e2e;
      bottom: 20px;
    }

    ul {
      list-style: none;
      padding: 0;
      position: relative;
      margin-bottom: 0;

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      span {
        color: $white;
      }
    }
  }
}

.reaction {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 20px;
  z-index: 999;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  border: 1px solid #ffffff19;
  background: #292e2e;
  padding: 5px 10px;
  border-radius: 4px;
  list-style: none;
  margin-bottom: 0;

  &::after {
    content: "";
    position: absolute;
    right: -10px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #292e2e;
    bottom: -5px;
  }

  li {
    margin-bottom: 0;

    i {
      cursor: pointer;
    }
  }
}

.reply-message {
  position: relative;
  background: #1c1f1f;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  word-break: break-word;

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  .user {
    font-size: 14px;
    font-weight: 500;
    color: $purple;
  }

  p {
    margin-bottom: 0;
    color: $gray4;
  }
}

.reply-content {
  opacity: 0.5;
  border: 1px solid #292e2e;
  background: #292e2e;
  padding: 5px;
  border-radius: 4px;
}

.current-reaction {
  ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;

    li {
      cursor: pointer;
      margin-bottom: 0;
      margin-right: 5px;

      span {
        display: inline-flex !important;
        flex-direction: column;
        margin-right: 0 !important;
        align-items: center;
        font-size: 10px;
        font-family: sfPro;
        color: $white !important;
      }
    }
  }
}

.heart {
  color: #ee1d24;
}

.thumb {
  color: #286efa;
}

.face {
  color: #ffe706 !important;
}

.header-tab-title {
  color: $gray3;
  font-family: sfPro;
  padding-top: 20px;
  margin-bottom: 28px;

  h4 {
    font-weight: 500;
    margin-bottom: 4px;
    text-transform: uppercase;
    font-size: 16px;
    color: $white;
    padding-bottom: 10px;
  }

  h2 {
    font-weight: 600;
    margin-bottom: 0;
    //padding-left: 1rem;
  }
}

// register game profile page
.profile-img-container {
  display: flex;
  justify-content: space-around;
  align-items: center;

  img {
    border-radius: 50%;
    width: 89px;
    height: 89px;
  }
}

.gamestag {
  .cont {
    margin-right: 10px;
    margin-bottom: 20px;
    // cursor: pointer;

    h3 {
      font-size: 18px;
      color: $white;
      margin-bottom: 20px;
    }
  }

  .pill {
    border-radius: 20px;
    padding: 5px 15px;
    background: $black3;
    color: $gray3;
    text-align: center;
    position: relative;
    width: 150px;
    max-width: 100%;
    word-break: break-word;
  }

  .close {
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: 0;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: $white;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }
}

// single challenge
.single-content {
  .description {
    color: #707070;
  }

  .challenge-info {
    border: 1px solid rgb(242 242 242 / 4%);
    border-radius: 30px;
    background-color: rgb(255 255 255 / 4%);
    padding: 5px 20px;
    margin-right: 20px;

    span {
      color: rgb(242 242 242 / 35%);
      display: block;
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 425px) {
    .challenge-info {
      border: 1px solid rgba(242, 242, 242, 0.04);
      border-radius: 1rem !important;
      background-color: rgba(255, 255, 255, 0.04);
      padding: 10px 5px 5px 7px !important;
      margin-right: 5px !important;
    }
  }

  .img-content {
    position: relative;

    &::before {
      content: "";
      background: transparent linear-gradient(39deg,
          rgb(35 27 77 / 60%) 0%,
          rgb(36 7 27 / 60%) 64%,
          rgb(16 14 44 / 60%) 100%) 0% 0% no-repeat padding-box;
      width: 100%;
      height: auto;
      position: absolute;
    }

    .img {
      width: 100%;
      border-radius: 30px;
    }
  }

  .imgChallenge {
    background: transparent linear-gradient(39deg, #231b4d 0%, #24071b 64%, #100e2c 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 150px #0000004d;
    border-radius: 1.875rem !important;
    opacity: 0.65;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  @media only screen and (max-width: 767px) {
    .imgChallenge {
      margin-top: 0rem !important;
    }
  }

  .tab-header {
    border-bottom: 1px solid #76767638;

    span {
      padding: 10px 7px 20px;
      color: #f2f2f25a;
      font-size: 16px;
      position: relative;
      display: inline-block;
      cursor: pointer;
    }

    .active {
      color: $white;

      &::after {
        height: 2px;
        width: 70px;
        background: $white;
        position: absolute;
        content: "";
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }
  }

  @media only screen and (max-width: 374px) {
    .tab-header {
      display: flex;
    }
  }

  .tab-body {
    transition: opacity 0.5s ease-in;
    height: 100%;
    opacity: 1;
    visibility: visible;
    margin-top: 10px;

    .box {
      background: $black4;
      border-radius: 15px;
      height: 55px;
      border: 1px solid #ffffff07;
      color: $gray3;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 30px;
      cursor: pointer;
      font-size: 12px;
    }
  }

  .hide {
    transition: opacity 0.5s ease-out;
    opacity: 0;
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

.bg-gray {
  background: rgb(242 242 242 / 3%);
  padding: 10px;
}

.blue {
  color: $blue;
}

.orange {
  color: $orange;
}

.pink {
  color: $pink;
}

.green {
  color: $ligthGreen;
}

.btn-pink-op {
  background: #d200561a;
  border: 1px solid #d2005633;
  color: $pink;
  padding: 5px 15px;
  border-radius: 20px;
  margin-right: 10px;
}

.btn-green-op {
  background: #54b9471a;
  border: 1px solid #54b94734;
  color: #54b947f4;
  padding: 5px 15px;
  border-radius: 20px;
}

.btn-orange-op {
  background: #ff85471a;
  border: 1px solid #ff854734;
  color: #ff8547;
  padding: 5px 15px;
  border-radius: 20px;
}

.btn-white-trans {
  border: 1px solid #ffffff36;
  max-width: 100%;
  width: 200px;
  height: 50px;
  color: $white;
  border-radius: 12px;
  background: transparent;
  font-family: "sfPro";
}

.btn-full {
  width: 100% !important;
}

.btn-pink {
  background: $pink;
}

.field-type2-cont {
  margin-bottom: 20px;

  label {
    font-family: sfPro;
    color: $white;
    margin-bottom: 10px;
  }

  .field-type2 {
    align-items: center;
    background: $black4;
    border: 0;
    height: 55px;
    display: flex;
    padding: 0 15px;
    border-radius: 10px;
    position: relative;

    input,
    select {
      color: #f2f2f2 !important;
      font-size: 16px;
      font-family: "sfPro";
      width: 100%;
      background: transparent;
      border: 0 !important;
      outline: none;
      z-index: 2;

      option {
        background: $black4;
      }
    }

    select {
      text-indent: 1px;
      text-overflow: "";
      z-index: 1;
    }

    svg {
      position: absolute;
      right: 20px;
    }
  }
}

.btn-blue {
  background: $blue;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  color: $white;
  font-family: "sfPro";
  font-size: 14px;
  margin-top: 20px;
}

// challenges
.card-challenge {
  background: $black3;
  border-radius: 30px;
  margin-bottom: 20px;

  .bg-pict {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    height: 180px;
    background-size: cover;
    background-position: center;
    position: relative;
  }

  .status {
    background: $black3;
    position: absolute;
    top: 15px;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    color: $white;
    font-size: 12px;
    left: 20px;
    margin: 0;
    text-transform: capitalize;
  }

  .price {
    background: $pink;
    position: absolute;
    bottom: -15px;
    display: inline-flex;
    padding: 5px 10px;
    border-radius: 20px;
    color: $white;
    font-size: 12px;
    right: 20px;
  }

  .content {
    padding: 20px 10px;

    h4 {
      color: $white;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    p {
      color: $gray3;
      font-size: 14px;
    }

    a {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.btn-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
}

.buble-popup {
  position: fixed;
  display: flex;
  background: $black3;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  align-items: center;
  box-shadow: 1px 3px 16px 3px $gray4;
  justify-content: center;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  cursor: pointer;
}

.swal-icon--success {

  &::after,
  &::before {
    background: transparent;
  }
}

.swal-icon--success__hide-corners {
  background: transparent;
}

@media (max-width: 1540px) {
  .live-chat {
    .messages {
      min-height: 65vh;
    }
  }
}

@media (max-width: 992px) {

  .main-bg,
  .main-bg-2 {
    margin-left: 0 !important;
    padding-left: 0px;
  }

  .profile-img-container {
    display: block;
    text-align: center;
    margin-bottom: 20px;

    #photo,
    button {
      width: 100%;
      margin-top: 10px;
    }
  }

  .live-chat-mb {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 40px;
    display: none;
  }

  .live-chat-mb.active {
    display: block;
    box-shadow: 1px 3px 16px 3px $gray4;
  }
}

// Market

.market-items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
  margin: 0 auto;
}

.market-item-container {
  margin-bottom: 45px;
  position: relative;
  padding-bottom: 60px;
}

.nft-card {
  box-shadow: -2px 2px 14px 1px #8c51e1;
  border-radius: 8px;
  background-color: #1a1a2e;
  height: 100%;
}

.nft-card-image-container {
  padding: 30px;
}

.nft-bg {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
}

.nft-card-content {
  background: transparent;
  padding: 20px;
  text-align: center;
}

.nft-card-title {
  font-size: 1.25rem;
  color: #e0aaff;
  margin-bottom: 10px;
}

.nft-card-description {
  color: #c4b2d6;
  font-size: 1rem;
}

.nft-card-actions {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  position: absolute;
  left: 0;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 0;
}

.nft-card-button {
  width: 100%;
  height: 50px;
  margin-right: 10px;
  background-color: #8c51e1;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.nft-card-button:hover {
  background-color: #6d3ebc;
}

.nft-card-link {
  margin-left: 10px;
  text-align: center;
  line-height: 50px;
  background-color: transparent;
  border: 2px solid #8c51e1;
  color: #8c51e1;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nft-card-link:hover {
  background-color: #8c51e1;
  color: white;
}

//My Assets
.nft-item {
  // padding: 20px;
  // background-color: #1a1a2e;
  // border-radius: 8px;
  // box-shadow: -2px 2px 14px 1px #8c51e1;
  // text-align: center;

  position: relative; /* Asegura que el marker se posicione respecto a este contenedor */
  padding: 20px;
  background-color: #1a1a2e;
  border-radius: 8px;
  box-shadow: -2px 2px 14px 1px #8c51e1;
  text-align: center;
}

.nft-item-selected{
  @extend .nft-item;
  box-shadow: -2px 2px 14px 1px #6be151;
}
.nft-item:hover{
  background-color: #2c2c49;
  cursor: pointer;
}

.nft-item img {
  max-width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
  pointer-events: none;
  user-select: none;
}

.nft-item p {
  color: #e0aaff;
  margin-bottom: 10px;
  font-size: 16px;
}

.pixelFont {
  font-family: 'DogicaPixel', sans-serif,
  
}

.connect-button-zksync {
  padding: 10px;
  background-color: #8c51e1;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 15px;
  margin-bottom: 25px;
}

.connect-button-zksync:hover {
  background-color: #6e3db5;
}

.connect-button-zksync:focus {
  outline: none;
}

.no-nfts-message {
  color: #ff4f4f;
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  background-color: #1a1a2e;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.no-nfts-submessage {
  font-size: 12px;
}
.marker{
  position: absolute;
  top: 9px;
  right: 9px;
  width: 15px;
  height: 15px;
  background-color: rgb(201, 213, 254);
  z-index: 1;
  color: rgb(3, 69, 0);
  font-weight: bold;
  font-size: 20px;
  display: flex; /* Usar Flexbox para alinear el contenido */
  align-items: center; /* Alinea verticalmente */
  justify-content: center; /* Alinea horizontalmente */

}
.transfer-assets {
  position: fixed;
  display: flex;
  margin-top: 0px;
  right: 20px;
  background-color: #6e3db5;
  z-index: 2;
  color: white;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.transfer-assets:hover {
  background-color: #9364d4;
  cursor: pointer;
}

.transfer-assets:active {
  transform: translateY(2px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}

.transfer-assets-text {
  margin: 5px;
  pointer-events: none;
  user-select: none;
}

.transfer-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 20px;
  z-index: 2;
}

.form-container {
  position: fixed;
  display: flex;
  margin-top: 40px !important;
  right: 20px;
  background-color: white;
  z-index: 2;
  color: black;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.wallet-input {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 185px;
  font-size: 12px !important;
  overflow: hidden;
}

#scrolling-placeholder::placeholder {
  display: inline-block;
  white-space: nowrap;
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.submit-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  width: 50px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.submit-button:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color:#27782a; ;
}

// Pagination Transaction Table
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 20px;
}

.pagination-button {
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.pagination-button:hover {
  background-color: #f0f0f0;
  border-color: #ccc;
}

.pagination-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.pagination-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  border-color: #ddd;
}

.pagination-dots {
  padding: 5px;
  font-size: 16px;
  color: #666;
}
.tab-wallet{
  color: #ffffff;
}

.tab-wallet:hover{
  color: #808080;
  cursor: pointer;
}

.error-prompt {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  text-align: center;
}